import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Card, Divider, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// utils
import { useSnackbar } from 'notistack';
import FormProvider, { RHFTextField } from '../../../components/hook-form';
// redux
import { getLeadsetupConfig } from '../../../services/leadsetup/getleadsetupconfig';
import { updateLeadsetupconfig } from '../../../services/leadsetup/updateLeadsetupconfig';

// ----------------------------------------------------------------------

export default function LeadSetupForm() {
  const { enqueueSnackbar } = useSnackbar();

  const [leadConfigData, setLeadConfigData] = useState({});

  const [demoConfigType, setDemoConfigType] = useState('');

  const [trialConfigType, setTrialConfigType] = useState('');

  const NewUserSchema = Yup.object().shape({
    demoUrl: Yup.string().url('Invalid URL format').nullable(), // Allows null or undefined values if the field is optional
    trialUrl: Yup.string().url('Invalid URL format').nullable(),
  });

  const defaultValues = useMemo(
    () => ({
      demoUrl: leadConfigData?.demoConfig?.url || '',
      trialUrl: leadConfigData?.trialConfig?.url || '',
    }),
    [leadConfigData?.demoConfig?.url, leadConfigData?.trialConfig?.url]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    const accData = JSON.parse(localStorage.getItem('profile'));

    const formData = new FormData();
    formData.append('resellerId', accData?.reseller_id);
    formData.append('userId', accData?.user_id);
    formData.append('orgId', accData?.org_id);
    formData.append(
      'leadConfig',
      JSON.stringify({
        demoConfig: {
          type: demoConfigType,
          url: data?.demoUrl,
        },
        trialConfig: {
          type: trialConfigType,
          url: data?.trialUrl,
        },
      })
    );

    try {
      const res = await updateLeadsetupconfig(formData, accData?.reseller_id);
      const { leadConfig } = res || {};
      const parsedConfig = JSON.parse(leadConfig);
      setLeadConfigData(parsedConfig);
      enqueueSnackbar('Configuration Updated Successfully!');
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    if (leadConfigData) {
      reset(defaultValues);
    }
  }, [defaultValues, leadConfigData, reset]);

  useEffect(() => {
    const getData = async () => {
      try {
        const accData = JSON.parse(localStorage.getItem('profile'));
        const res = await getLeadsetupConfig(accData?.reseller_id);

        const { leadConfig } = res || {};
        const parsedConfig = JSON.parse(leadConfig);
        setLeadConfigData(parsedConfig);
        setDemoConfigType(parsedConfig?.demoConfig?.type);
        setTrialConfigType(parsedConfig?.trialConfig?.type);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ p: 3, maxWidth: '800px' }}>
        <Divider>Demo Leads</Divider>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
          <FormControl sx={{ minWidth: '180px' }} size="small">
            <InputLabel id="demo-simple-select-label">Select Config Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={demoConfigType}
              label="Select Config Type"
              onChange={(e) => {
                setDemoConfigType(e.target.value);
              }}
            >
              <MenuItem value="url">URL</MenuItem>
              <MenuItem value="webhook">CRM Webhook</MenuItem>
            </Select>
          </FormControl>

          <RHFTextField
            size="small"
            name="demoUrl"
            label={leadConfigData?.demoConfig?.type === 'url' ? 'URL' : 'CRM Webhook URL'}
            placeholder={
              leadConfigData?.demoConfig?.type === 'url'
                ? ' Enter Demo URL'
                : 'Enter CRM Webhook URL'
            }
          />
        </Stack>

        <Divider sx={{ mt: 3 }}>Trails Leads</Divider>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
          <FormControl sx={{ minWidth: '180px' }} size="small">
            <InputLabel id="demo-simple-select-label">Select Config Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={trialConfigType}
              label="Select Config Type"
              onChange={(e) => {
                setTrialConfigType(e.target.value);
              }}
            >
              <MenuItem value="url">URL</MenuItem>
              <MenuItem value="webhook">CRM Webhook</MenuItem>
            </Select>
          </FormControl>

          <RHFTextField
            size="small"
            name="trialUrl"
            label={leadConfigData?.trialConfig?.type === 'url' ? 'URL' : 'CRM Webhook URL'}
            placeholder={
              leadConfigData?.trialConfig?.type === 'url'
                ? 'Enter Demo URL'
                : 'Enter CRM Webhook URL'
            }
          />
        </Stack>

        <Stack spacing={3} alignItems="flex-start" sx={{ mt: 3 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            startIcon={<CloudUploadIcon />}
          >
            Save
          </LoadingButton>
        </Stack>
      </Card>
    </FormProvider>
  );
}
