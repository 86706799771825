// utils
import axios from '../../utils/axios';

import { WHATSAPP_DEMO_API_KEY } from '../../config-global';

const updateLeadsetupconfig = async (leadSetupData, resellerId) => {
  try {
    const response = await axios({
      method: 'put',
      url: `${WHATSAPP_DEMO_API_KEY}/api/whitelabel-config/${resellerId}`,
      data: leadSetupData,
    });
    return response.data;
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
};

export { updateLeadsetupconfig };
