// utils
import axios from '../../utils/axios';

import { WHATSAPP_DEMO_API_KEY } from '../../config-global';

const getLeadsetupConfig = async (resellerId) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${WHATSAPP_DEMO_API_KEY}/api/whitelabel-config/${resellerId}`,
      // url: `http://localhost:5000/api/partner-details/${resellerId}`,
    });
    return response.data;
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
};

export { getLeadsetupConfig };
