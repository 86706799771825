import GoogleIcon from '@mui/icons-material/Google';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined'; // routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

const icon = (name) => <SvgColor src={`/assets/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  users: icon('users'),
  whatsApp: icon('whatsApp'),
  partners: icon('partners'),
  accountSetup: icon('accountSetup'),
  reports: icon('reports'),
  whitelable: icon('whitelable-setup'),
  profile: icon('profile'),
};

const whatsappDemoUrl = new URL(window.location.href);

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    // subheader: 'general v4.1.0',
    items: [
      { title: 'Customers', path: PATH_DASHBOARD.customers.root, icon: ICONS.users },
      { title: 'Partners', path: PATH_DASHBOARD.partners.root, icon: ICONS.partners },
      {
        title: 'Google Workspace',
        path: PATH_DASHBOARD.googleWorkspace.root,
        icon: <GoogleIcon />,
        children: [
          {
            title: 'Customers',
            path: PATH_DASHBOARD.googleWorkspace.customers,
          },
          {
            title: 'Transactions',
            path: PATH_DASHBOARD.googleWorkspace.transactions,
          },
        ],
      },
      {
        title: 'WhatsApp',
        path: PATH_DASHBOARD.whatsApp.root,
        icon: ICONS.whatsApp,
        children: [
          {
            title: 'Partners',
            path: PATH_DASHBOARD.whatsApp.partners,
          },
          {
            title: 'Customers',
            path: PATH_DASHBOARD.whatsApp.customers,
          },
          {
            title: 'Transactions',
            path: PATH_DASHBOARD.whatsApp.transactions,
          },

          {
            title: 'Templates',
            path: PATH_DASHBOARD.whatsApp.templates,
          },
          {
            title: 'Start Demo',
            path: `${whatsappDemoUrl.origin}/whatsapp/demo`,
          },
          {
            title: 'Customize Demo',
            path: PATH_DASHBOARD.whatsApp.customizeDemo,
          },
          {
            title: 'Fix Display Name',
            path: PATH_DASHBOARD.whatsApp.fixDisplayName,
          },

          // {
          //   title: 'Rates',
          //   path: PATH_DASHBOARD.whatsApp.rates,
          // },
          // {
          //   title: 'Settings',
          //   path: PATH_DASHBOARD.whatsApp.settings,
          // },
        ],
      },
      { title: 'Reports', path: PATH_DASHBOARD.reports, icon: ICONS.reports },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      {
        title: 'users',
        path: PATH_DASHBOARD.users.root,
        icon: ICONS.users,
        // children: [
        //   { title: 'Four', path: PATH_DASHBOARD.user.four },
        //   { title: 'Five', path: PATH_DASHBOARD.user.five },
        //   { title: 'Six', path: PATH_DASHBOARD.user.six },
        // ],
      },
      {
        title: 'Partner Profile',
        path: PATH_DASHBOARD.partners.partnerProfile,
        icon: ICONS.profile,
      },
      {
        title: 'Whitelable Config',
        path: PATH_DASHBOARD.whitelableConfig,
        icon: ICONS.whitelable,
      },
      {
        title: 'Lead Setup',
        path: PATH_DASHBOARD.leadsetup,
        icon: <LeaderboardOutlinedIcon />,
      },
    ],
  },
];

export default navConfig;
